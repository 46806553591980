<template>
    <div class="common-table">
        <div class="common-table__title">
            自助报告列表
            <div style="font-size: 14px;margin-left: 20px;">
                标题：
                <el-input style="width: 220px" v-model="titleValue" :placeholder="'请输入标题'" clearable></el-input>
                <el-button style="margin-left: 10px;" type="primary" @click="outSearch">筛选</el-button>
            </div>
            <div class="table-operate">
                <!--<el-button type="text" icon="el-icon-s-help" @click="goGeneral">去生成</el-button>-->
                <el-select v-model="type" placeholder="请选择报告类型" @change="typeChange">
                    <el-option value="" label="报告类型不限"></el-option>
                    <el-option value="药品分析报告(简版)" label="药品分析报告(简版)"></el-option>
                    <el-option value="药品分析报告" label="药品分析报告"></el-option>
                    <el-option value="企业分析报告" label="企业分析报告"></el-option>
                    <el-option value="药品代理分析报告" label="药品代理分析报告"></el-option>
                    <el-option value="企业药品分析报告" label="企业药品分析报告"></el-option>
                </el-select>
            </div>
        </div>

        <div class="ts-table flex flex-item flex-column">
            <ts-table-edit ref="table" class="flex-item" :cols="cols" :data="list" :page-param="pageParam"
                :loading="loading" @handleSort="sort" full @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :cmds="cmds">
                <template v-slot:slot_reportFileEdit="{ props }">
                    <!--<template v-if="props.row.temporaryStatus === 1">-->
                    <!--<el-link size="mini" type="success" style="font-size: 12px"-->
                    <!--:underline="false" @click="goEdit(props.row)">继续编辑</el-link>-->
                    <!--</template>-->
                    <!--<template v-else>-->
                    <!--<el-link size="mini" type="success" style="font-size: 12px"-->
                    <!--:underline="false" @click="goEdit(props.row)">继续编辑</el-link>-->
                    <!--&lt;!&ndash;<el-link v-show="props.row.reportFile" size="mini" target="_blank" type="primary" style="font-size: 12px"&ndash;&gt;-->
                    <!--&lt;!&ndash;:href="props.row.reportFile" :underline="false">查看</el-link>&ndash;&gt;-->
                    <!--</template>-->
                    <el-link size="mini" type="success" style="font-size: 12px" :underline="false"
                        @click="goEdit(props.row)">继续编辑</el-link>
                </template>
            </ts-table-edit>
        </div>
    </div>

</template>

<script>
import TsTableEdit from '@/pages/make/TsTableEdit'
import commonMixin from '@/pages/make/commonMixin'
export default {
    components: { TsTableEdit },
    mixins: [commonMixin],
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'generalList',
            pageName: '自助报告列表',

            defaultSort: {
                updatedAt: 'desc'
            },

            api: {
                page: 'sys/sysUserReport/querySysUserReportPage',
                del:'sys/sysUserReport/deleteById',
            },
            cols: [
                { width: 300, key: 'title', value: '标题' },
                { width: 100, key: 'templateName', value: '报告类型' },
                { width: 120, key: 'updatedAt', value: '生成时间' },
                { width: 100, key: 'reportFileEdit', value: '编辑文件', type: 'file' },
                { width: 100, key: 'reportFile', value: '报告文件', type: 'file' },
            ],
            type: '',
            titleValue: '',
            cmds: [
                {
                    txt: '删除',
                    type: 'danger',
                    event: this.del,
                    whether(row) {
                        return row.isDel 
                    },
                }
            ],
        }
    },
    methods: {
        setNewList(arr){
            const obj = this.$help.store.get('newParam')
            if(arr && arr.list !== 0){
                arr.map(item=>{
                    item.isDel = true
                    const key = item.fileKey.split('-')[0]
                    if(obj && key === obj.key){
                        item.isDel = false
                    }
                    return item
                })
            }
            return arr
        },
        goGeneral() {
            const { href } = this.$router.resolve({
                path: '/report/general',
            });
            window.open(href, "_blank");
        },
        typeChange() {
            this.newVale(this.type,'templateName',1)
        },
        goEdit(row) {
            const { href } = this.$router.resolve({
                path: '/report/general?id=' + row.id,
            });
            window.open(href, "_blank");
        },
        outSearch() {
            this.newVale(this.titleValue,'title',0)
        },
        newVale(value,type,num){
            const index = this.searchCols.findIndex(item => item.field.fieldName === type)
            if (value) {
                if(index === -1){
                    this.searchCols.push({
                        field: {
                            fieldName: type,
                            listQueryModel: num
                        },
                        model: num === 0 ? value : [value]
                    })
                }else{
                    this.searchCols[index].model = num === 0 ? value : [value]
                }
            }else{
                if(index !== -1){
                    this.searchCols.splice(index,1)
                }
            }
            this.init()
        }

    }
}
</script>